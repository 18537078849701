<template>
    <b-overlay :show="is_busy" rounded="sm">
        <b-container fluid>
            <div id="main">
                <b-row class="mb-2">
                    <b-col md="4">
                        <h2><strong>List of Learners</strong></h2>
                    </b-col>
                </b-row>

                <div class="card">
                    <div class="card-body">
                        <div class="row my-1">
                            <div class="col-md-6 col-12">
                                <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                                class="my-1"
                                ></b-form-input>
                            </div>

                            <div class="col-md-6 col-12 mt-1">
                                <b-button variant="outline-danger" size="sm" class="ml-1" v-if="action.selected.length" @click="onDeleteAll"><i class="fa fa-trash"></i> Delete Selected</b-button>
                                <b-button disabled size="sm" variant="outline-danger" v-else class="ml-1"> <i class="fa fa-trash"></i> Delete Selected</b-button>   
                                <b-button variant="outline-info" size="sm" class="ml-1" @click="printBarcode">Print Barcode</b-button>                                             
                                <b-button variant="outline-secondary" size="sm" @click="generate" class="ml-1">Barcode Generate</b-button>  
                            </div>
                        </div>
                    
                        <b-table
                            responsive
                            :items="items"
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :bordered="bordered" 
                            :hover="hover"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            show-empty
                            id="my-table"
                            small
                            @filtered="onFiltered"
                        >   
                            <template #head(index)="">
                                <input type="checkbox" v-model="selectAll">
                            </template>

                            <template #cell(actions)="room">
                                <b-dropdown id="dropdown-right" text="Action" variant="info" size="sm">
                                    <b-dropdown-item href="javascript:void(0)" @click="edit(room.item)">Edit</b-dropdown-item>
                                    <b-dropdown-item href="javascript:void(0)" @click="view(room.item)">View</b-dropdown-item>
                                    <b-dropdown-item href="javascript:void(0)" @click="attendance(room.item)">Attendance History</b-dropdown-item>
                                    <b-dropdown-item href="javascript:void(0)" @click="onDeleteAll(room.item.id)">Delete</b-dropdown-item>
                            </b-dropdown>
                            </template>

                            <template #cell(name)="student">
                                {{ student.item.lastname }} {{ student.item.firstname }} {{ student.item.middlename }}
                            </template>

                            <template #cell(gender)="student">
                                {{ student.item.gender | capitalize }}
                            </template>

                            <template #cell(attendance)="student">
                                <!-- <span v-html="student.item.barcode_attendance"></span> -->
                                <img :src="student.item.barcode_attendance" class="img-fluid" style="height:100px">
                            </template>

                            <template #cell(index)="data">
                                <!--{{ data.index + 1 }}-->
                                <input type="checkbox" v-model="action.selected" :value="data.item.id" number>
                            </template>
                        </b-table>
                    </div>

                    <div class="card-footer">
                        <b-row class="mb-2">
                            <b-col md="4">
                                <b-form-group
                                    label="Showing"
                                    label-for="per-page-select"
                                    label-cols-md="4"
                                    label-align-sm="right"
                                    label-size="sm"
                                    class="mb-0"
                                >
                                    <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                    class="def"
                                    ></b-form-select>
                                    of {{ items.length }} Items
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-pagination
                                    aria-controls="my-table"
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
            <div id="barcode">
                <div class="card">
                    <div class="card-header">
                        <b-button v-print="printObj" variant="outline-info" size="sm">Print All</b-button>  
                        <div id="loading" v-show="printLoading"></div>
                        <b-button variant="outline-success" size="sm" @click="goBack">Return</b-button>  
                    </div>
                    <div class="card-body">
                        <div class="row mt-3" id="printMe">
                            <div class="col-md-3 border m-2" v-for="item in items" :key="item.id">
                                <img :src="item.barcode_attendance" class="img-fluid mt-2" style="height:200px; width:300px">
                                <p style="padding:10px">{{ item.lastname }} {{ item.firstname }} {{ item.middlename }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>
    </b-overlay>
</template>

<script>
    import axios from 'axios';
    
    export default {
        data() {
            return {
                is_busy: false,
                editMode: false,
                title: '',
                items: [],
                fields: [
                    { key: 'index', label: '' },
                    { key: 'class', label: 'Class'},
                    { key: 'name', label: 'Name'},
                    { key: 'roll', label: 'Roll'},
                    { key: 'gender', label: 'Gender'},
                    { key: 'attendance', label: 'Attendance Code'},
                    { key: 'actions', label: 'Actions' }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 15,
                bordered: true,
                hover: true,
                fixed: true,
                pageOptions: [5, 10, 15, 25, 50, 100, { value: 100, text: "All" }],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: ''
                },
                printLoading: true,
                printObj: {
                    id: "printMe",
                    extraCss: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css",
                    extraJs: "https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js",
                    extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
                    beforeOpenCallback (vue) {
                        vue.printLoading = true
                    },
                    openCallback (vue) {
                        var doc = document.getElementById("barcode");
                        var main = document.getElementById("main");
                        doc.style.display = "none";
                        main.style.display = "block";
                        vue.printLoading = false
                    },
                    closeCallback (vue) {
                        
                    }
                },
                is_busy: false,
                editmode: false,
              
                action: {
                    selected: [],
                    id: '',
                },
            }
        },

        created() {
            this.roleHelper(['1', '5']);
            this.load();
        },

        methods: {
            load() {
                if(this.is_busy) return;
                this.is_busy = true;
                axios.get('/students')
                .then(res => {
                    this.items = res.data.data;
                    this.totalRows = this.items.length
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.is_busy = false;
                });
            },
            
            printBarcode() {
                console.log(this.items)
                var doc = document.getElementById("barcode");
                var main = document.getElementById("main");
                doc.style.display = "block";
                main.style.display = "none";
            },

            generate() {
                if(this.is_busy) return;
                this.is_busy = true;
                axios.get('/attendance/generate')
                .then(() => {
                    Swal.fire(
                        "Success!",
                        "Barcodes Generated for leraners. Check learner's profile.",
                        "success"
                    );
                    this.load();
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.is_busy = false;
                    this.load();
                });
            },
            
            goBack(){
                var doc = document.getElementById("barcode");
                var main = document.getElementById("main");
                doc.style.display = "none";
                main.style.display = "block";
            },

            view(data) {
                this.$router.push({path: '/administration/student/'+ data.id });
            },

            attendance(data) {
                this.$router.push({path: '/attendance/student/'+ data.id });
            },

            edit(data) {
                this.$router.push({ path: "/administration/student/edit/" + data.id});
            },

            onDeleteAll(id) {
                if(id){
                    this.action.selected.push(id);
                }
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!, Deleting these student's info will wipe out all related data",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete!"
                })
                .then(result => {
                    if (result.value) {
                    axios.get('/students/delete', { params: this.action})
                    .then(() => {
                        Swal.fire(
                            "Deleted!",
                            "Student(s) deleted.",
                            "success"
                        );
                        this.load();
                    })

                    .catch(() => {
                        Swal.fire(
                            "Failed!",
                            "Ops, Something went wrong, try again.",
                            "warning"
                        );
                    });
                    }
                });
                this.load();
            },

            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },

        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                .filter(f => f.sortable)
                .map(f => {
                return { text: f.label, value: f.key }
                })
            },

            selectAll: {
                get: function () {
                    return this.items ? this.action.selected.length == this.items.length : false;
                },

                set: function (value) {
                    var selected = [];

                    if (value) {
                        this.items.forEach(function (room) {
                            selected.push(room.id);
                        });
                    }

                    this.action.selected = selected;
                }
            }
        },
    }
</script>
<style>
    .cont {
        background: #F3F2F7;
    }

    .def {
        width: 70px;
    }

    #barcode {
        display: none;
    }
</style>